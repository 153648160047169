var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"Stock","filters":_vm.activeFilters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-select',{attrs:{"items":_vm.categories,"item-text":function (item) { return ((item.code) + " - " + (item.description)); },"item-value":function (item) { return ((item.code) + "-" + (item.description)); },"label":"Category"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'categoryName',
          'Category',
          _vm.category.value
        )}},model:{value:(_vm.category.value),callback:function ($$v) {_vm.$set(_vm.category, "value", $$v)},expression:"category.value"}}),_c('v-text-field',{attrs:{"label":"Code"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('code', 'Code', _vm.code.value)}},model:{value:(_vm.code.value),callback:function ($$v) {_vm.$set(_vm.code, "value", $$v)},expression:"code.value"}}),_c('v-text-field',{attrs:{"label":"Description"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'description',
          'Description',
          _vm.description.value
        )}},model:{value:(_vm.description.value),callback:function ($$v) {_vm.$set(_vm.description, "value", $$v)},expression:"description.value"}})],1),_c('stock-table',{attrs:{"loading":_vm.loading,"stocks":_vm.filteredItems,"categories":_vm.categories,"total-items":_vm.totalCount},on:{"load-more":_vm.loadMoreStocks,"update:sort":_vm.handleSort}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }